.projectText {
  color: gray;
  font-weight: 800;
  font-size: 16px;
  position: absolute;
  width: 535px;
  left: 250px;
  display: flex;
  flex-direction: column;
  bottom: 20px;
}
.details {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 0;
  margin-bottom: 10px;
}
.details a {
  text-decoration: underline;
  padding: 1%;
  color: gray;
}
.projects {
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
}

.caret {
  padding-top: 4.5px;
  font-size: 28px;
  padding-right: 20px;
  cursor: pointer;
  transition: 1s ease;
  transform: rotate(0deg);
}
.caretActive {
  padding-top: 4.5px;

  font-size: 28px;
  cursor: pointer;
  transition: 1s ease;
  transform: rotateY(3.142rad);
}

.dropdown {
  transition: 1s ease;
  position: absolute;
  right: 400px;
  top: 120px;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 2px lightgray;
}

.dropdown p {
  width: 250px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 0;
  position: relative;
  z-index: 10;
  padding: 20px;
}
.projectHeaders {
  position: absolute;
  right: 0;
  transition: 1s ease;
}
