.App {
  text-align: center;
  background-color: #d9e5d6;
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  position: relative;
  width: 1300px;
  height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
}
.main .title {
  color: #d9e5d6;
  text-shadow: 2px 2px black;
  font-size: 75px;
  z-index: 2;
  position: absolute;
  top: -10px;
  left: 35px;
}
.main::before {
  content: '';
  position: absolute;
  width: 300px;
  height: 300%;
  background: linear-gradient(black, white);
  animation: rotate 40s linear infinite;
}

.main:after {
  content: '';
  position: absolute;
  background: #d9e5d6;
  inset: 1.8% 1%;
  border-radius: 16px;
}
.items {
  z-index: 2;
  position: absolute;
  top: 20%;
  left: 70px;
  color: gray;
  font-size: 30px;
  text-align: left;
}
.items:hover {
  color: lightgray;
}
.items p {
  transition: 0.5s ease;
}
.items p:hover {
  color: black;
  cursor: pointer;
}
.contentBox {
  z-index: 1;
  width: 65%;
  height: 80%;
  position: absolute;
  right: 60px;
  border-radius: 16px;
  overflow: hidden;
}
.contentBox::before {
  content: '';
  position: absolute;
  width: 30%;
  height: 100%;
  left: 670px;
  top: 300px;
  background: linear-gradient(gray, white);
  transform: rotate(-50deg) translate(50px);
}

.contentBox:after {
  content: '';
  position: absolute;
  background: #d9e5d6;
  inset: 10px 10px;
  border-radius: 16px;
  z-index: inherit;
}
.content {
  position: absolute;
  z-index: 2;
  display: flex;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.socialIcons {
  z-index: 2;
  color: gray;
  position: absolute;
  width: 140px;
  display: flex;
  justify-content: space-between;
  font-size: 40px;
  margin-top: 350px;
  left: 75px;
  height: 20px;
  padding: 0 10px;
}
.socialIcons > * {
  transition: 0.5s ease;
  margin: 10px;
}
.socialIcons a {
  color: gray;
}
.socialIcons > *:hover {
  color: black;
  cursor: pointer;
  font-size: 42px;
}
